<template>
  <div>

    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>
    <div class="content_section">
      <div class="content">
        <sub-title>토큰게임</sub-title>
        <leisure-game-comp-links></leisure-game-comp-links>
        <leisure-comp-tgames365-game-links></leisure-comp-tgames365-game-links>
        <router-view></router-view>
      </div>
    </div>
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>
    <scroll-top-comp></scroll-top-comp>

    <!--푸트메뉴-->
    <foot-comp></foot-comp>
  </div>

</template>

<script>
  import RightBarComp from "../../components/RightBarComp";
  import SubTitle from "../../components/SubTitle";
  import LeftBarComp from "../../components/LeftBarComp";
  import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
  import sportsConst from "../../common/sportsConst";
  import leisureConst from "../../common/leisureConst";
  import {getOnBetLeisureGames} from "../../network/leisureRequest";
  import ScrollTopComp from "../../components/ScrollTopComp";
  import TopbarComp from "../../components/TopbarComp";
  import FootComp from "../../components/FootComp";
  import RightBarBannerComp from "../../components/RightBarBannerComp";
  import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
  import LeisureGameLinks from "./LeisureGameLinks";
  import LeisureGameCompLinks from "./LeisureCompLinks";
  import LeisureCompGameLinks from "./LeisureCompDonghangGameLinks";
  import LeisureCompDonghangGameLinks from "./LeisureCompDonghangGameLinks";
  import LeisureCompCrownGameLinks from "./LeisureCompCrownGameLinks";
  import LeisureCompLotusGameLinks from "./LeisureCompLotusGameLinks";
  import {postionMixin, scrolltopMixin} from "../../common/mixin";
  import LeisureCompSkyGameLinks from "./LeisureCompSkyGameLinks";
  import LeisureCompBet365GameLinks from "./LeisureCompBet365GameLinks";
  import UserInfoComp from "../../components/UserInfoComp";
  import RightButtonsComp from "../../components/RightButtonsComp";
  import LeisureCompStarSadaliGameLinks from "./LeisureCompStarSadaliGameLinks";
  import LeisureCompTgames365GameLinks from "./LeisureCompTgames365GameLinks";
  import router from "../../router";

  export default {
    name: "Tgame365",
    mixins:[postionMixin],
    components: {
      LeisureCompTgames365GameLinks,
      LeisureCompStarSadaliGameLinks,
      RightButtonsComp,
      UserInfoComp,
      LeisureCompBet365GameLinks,
      LeisureCompSkyGameLinks,
      LeisureCompLotusGameLinks,
      LeisureCompCrownGameLinks,
      LeisureCompDonghangGameLinks,
      LeisureCompGameLinks,
      LeisureGameCompLinks,
      LeisureGameLinks,
      SportsLeftBarComp,
      RightBarBannerComp,
      FootComp, TopbarComp, ScrollTopComp, LeisureLeftComp, LeftBarComp, SubTitle, RightBarComp
    },
    data(){
      return {
        leisureConst:leisureConst,
        position:"토큰게임",
      }
    },
    created() {
      if(this.$store.state.userInfo.userGroup.bettgameable == sportsConst.NOT){
        this.$swal({
          title: '토큰게임 이용이 제한되였습니다',
          type: 'warning',
          showCancelButton: false,
          showConfirmButton: true
        })
        this.$router.push('/main')
      }
    }

  }
</script>

<style scoped>
  @import url("../../assets/css/base.css");
  @import url("../../assets/css/common.css");
  @import url("../../assets/css/style.css");
  @import url("../../assets/css/media.css");
  @import url("../../assets/css/sports.css");
  @import url("../../assets/css/minigame.css");
</style>